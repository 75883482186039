import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../../redux/actions/sidebarActions";
import axiosInstance from "../../axiosInstance";
import useError from "../../hooks/useError";
import { toastr } from "react-redux-toastr";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
  Input,
  Button,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellSlash, faBuilding, faEnvelopeOpen, faComments, faChartPie, faCogs, faCog, faArrowAltCircleRight, faUser, faUserLock, faCross, faTimes, faEnvelope, faBriefcaseMedical, faCarSide, faWrench, faArchive, faRoute } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import useLogout from "../../hooks/useLogout";
import NavbarSearch from "./Search";
import instance from "../../axiosInstance";

const NavbarDropdown = ({ children, count, header, footer, icon, active, indicator }) => (
  <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
    <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
      <FontAwesomeIcon icon={icon} className="align-middle" />
      {indicator ? <span className="indicator" /> : ""}
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, dismiss, dismissNotification, note_type, id, notes, reminder_date, is_read, case_id }) => (
  <ListGroupItem className="position-relative">
    <div onClick={() => dismissNotification(note_type, id) } className="text-danger cursor-pointer d-flex justify-content-end position-absolute" style={{top: '2%', right: '5%'}}>{dismiss}</div>
    <Row noGutters className="align-items-center position-relative">
      <Col xs={2}>{icon}</Col>
      <Col xs={10}>
        <div className="mb-2">
          {note_type === "UN" ? (
              <div style={{color: "inherit"}}>
                <u>Alert</u>
              </div>
            ) : (
              <a
                style={{color: "inherit"}}
                href={`/administration/cases/${case_id}/edit`}
              >
                <u>{`NASL-${case_id}`}</u> 
              </a>
          )}
        </div>
        <div className="text-dark">{notes}</div>
        <div className="text-muted small mt-1">{reminder_date}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarDropdowns = connect((store) => ({
  sidebar: store.sidebar,
}))(({ sidebar }) => {
  const { logout } = useLogout();

  const { handleError } = useError();
  const [changePasswordPopupOpen, setChangePasswordPopupOpen] = useState(false);
  const [showReminders, setShowReminders] = useState(true);
  const [old_password, setOldPassword] = useState("");
  const [login_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingInterval, setIsFetchingInterval] = useState(false);
  const [isDismissing, setIsdismissing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (login_password !== confirm_password) {
      toastr.error("Error", "New password and confirm password do not match!");
      return;
    }

    const passwordChangeData = {
      old_password,
      login_password,
      confirm_password,
    };

    try {
      setIsLoading(true);

      const response = await axiosInstance.put("/user/changePassword", passwordChangeData);

      toastr.success("Success", "Password changed successfully");
    } catch (error) {
      handleError(error);
    } finally {
      setChangePasswordPopupOpen(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setIsLoading(false);
    }
  };

  const handleCancle = () => {
    setChangePasswordPopupOpen(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const [notification, set_notifications] = useState(null);


  const fetchNotifications = async (isInterval = false) => {
    try {
      if (!isInterval) {
        setIsFetching(true);
      } else {
        setIsFetchingInterval(true);
      }
      const response = await instance.get(`/user/getNotifications`);
      set_notifications(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      if (!isInterval) {
        setIsFetching(false);
      } else {
        setIsFetchingInterval(false);
      }
    }
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(() => fetchNotifications(true), 20000);

    return () => clearInterval(interval);
  }, []);

  const dismissNotification = async (note_type, id) => {
    const body = {
      note_type: note_type,
      id: id,
    };
    try {
      setIsdismissing(true);
      toastr.success("The Notification is Dismissed!");
      await instance.put(`/user/updateNotification`, body);
      fetchNotifications();
    } catch (error) {
      console.log(error);
    } finally {
      setIsdismissing(false);
    }
  };


  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <NavbarDropdown header="New Notifications" icon={faBell} count={notification?.length} indicator={!!notification?.length}>
          {!isFetchingInterval && isFetching && (
            <Spinner color="primary" style={{ width: '2rem', height: '2rem' }} />
          )}
          {notification?.map((item) => {
            let icon = (
              <FontAwesomeIcon icon={faEnvelopeOpen} className="text-warning" />
            );

            if (item.note_type === "UN") {
              icon = <FontAwesomeIcon icon={faBell} />;
            } else if (item.note_type === "CN-PI"){
              icon = <FontAwesomeIcon icon={faBriefcaseMedical} />;
            } else if (item.note_type === "CN-Hire"){
              icon = <FontAwesomeIcon icon={faCarSide} />;
            } else if (item.note_type === "CN-Repair"){
              icon = <FontAwesomeIcon icon={faWrench} />;
            } else if (item.note_type === "CN-Storage"){
              icon = <FontAwesomeIcon icon={faArchive} />;
            } else if (item.note_type === "CN-Recovery"){
              icon = <FontAwesomeIcon icon={faRoute} />;
            } else {
              icon = (
                <FontAwesomeIcon icon={faEnvelope} />
              );
            }

            return (
              <NavbarDropdownItem
                key={item.id}
                dismiss={<FontAwesomeIcon icon={faTimes} className="cursor-pointer"/>}
                dismissNotification={dismissNotification}
                icon={icon}
                is_read={item.is_read}
                case_id={item.case_id}
                id={item.id}
                note_type={item.note_type} 
                notes={item.notes}
                reminder_date={item.reminder_date}
              />
            );
          })}
        </NavbarDropdown>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/profile">
              <DropdownItem>
                <FontAwesomeIcon icon={faUser} fixedWidth className="mr-2 align-middle" />
                View Profile
              </DropdownItem>
            </Link>
            <DropdownItem
              onClick={() => {
                setChangePasswordPopupOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faUserLock} fixedWidth className="mr-2 align-middle" />
              Change Password
            </DropdownItem>

            <Modal isOpen={changePasswordPopupOpen} centered>
              <ModalHeader>Change Password</ModalHeader>
              <Form onSubmit={handleSubmit}>
                <ModalBody className="m-3">
                  <FormGroup>
                    <Col lg={8} sm={12}>
                      <Label for="oldPassword">Old Password</Label>
                      <Input type="password" name="oldPassword" id="oldPassword" value={old_password} onChange={(e) => setOldPassword(e.target.value)} required />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col lg={8} sm={12}>
                      <Label for="newPassword">New Password</Label>
                      <Input type="password" name="newPassword" id="newPassword" value={login_password} onChange={(e) => setNewPassword(e.target.value)} required />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col lg={8} sm={12}>
                      <Label for="confirmPassword">Confirm Password</Label>
                      <Input type="password" name="confirmPassword" id="confirmPassword" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    </Col>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <div className="text-center mt-3">
                    <Button color="primary" outline onClick={handleCancle}>
                      <span>Cancel</span>
                    </Button>
                  </div>
                  <div className="text-center mt-3">
                    <Button color="primary" type="submit" disabled={isLoading}>
                      {isLoading && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
                      <span>Change Password</span>
                    </Button>
                  </div>
                </ModalFooter>
              </Form>
            </Modal>

            <DropdownItem divider />
            <DropdownItem onClick={logout}>
              <FontAwesomeIcon icon={faArrowAltCircleRight} fixedWidth className="mr-2 align-middle" />
              Sign out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = ({ sidebar }) => {

  // console.log(notification)

  return (
    <Navbar expand className="navbar-theme">
      {!sidebar.isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          <NavbarSearch />
          <NavbarDropdowns />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavbarDropdowns />
          <NavbarSearch />
          <NavbarToggle />
        </React.Fragment>
      )}
    </Navbar>
  );
};

export default connect((store) => ({
  sidebar: store.sidebar,
}))(NavbarComponent);
