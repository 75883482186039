import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useProfileContext } from "../../context/profile";
import Header from "../UI/Header";
import HeaderTitle from "../UI/HeaderTitle";
import SourceProgess from "./SourceProgess";
import DailyStatistics from "./DailyStatistics";
import MonthlyStatistics from "./MonthlyStatistics";
import Cases from "./Cases";
import PersonalNotes from "./PersonalNotes";
import Select from "react-select";
import { selectHideArrowStyle } from "./AdminDashboard";
import { months, years } from "../UI/forms/CustomDate";
import useError from "../../hooks/useError";
import instance from "../../axiosInstance";
import Reminders from "./Reminders";

const HireSupplierDashboard = memo(() => {
  const { profile } = useProfileContext();
  const { handleError } = useError();

  const date = new Date();

  const selectedYear = sessionStorage.getItem("bdo-selectedYear");
  const selectedMonth = sessionStorage.getItem("bdo-selectedMonth");
  const [month, setMonth] = useState(selectedMonth ? { value: +selectedMonth, label: months[+selectedMonth - 1] } : { value: date.getMonth() + 1, label: months[date.getMonth()] });
  const [year, setYear] = useState(selectedYear ? { value: selectedYear, label: selectedYear } : { value: date.getFullYear(), label: date.getFullYear() });

  const [dashboardData, setDashboardData] = useState(null);

  const getData = async () => {
    try {
      const { data } = await instance.get("/dashboard/bdo", {
        params: {
          current_year: year?.value || null,
          current_month: month?.value || null,
        },
      });
      setDashboardData(data.data);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [month, year]);

  const totalHires = Number(dashboardData?.total_hire) || 0;
  const totalPI = Number(dashboardData?.total_pi) || 0;
  const totalRepair = Number(dashboardData?.total_repair) || 0;
  const totalCases = totalHires + totalPI + totalRepair;

  return (
    <>
      <Container fluid>
        <Header>
          <div className="d-flex justify-content-between align-items-center">
            <HeaderTitle>Welcome, {profile?.full_name}</HeaderTitle>
            <div className="d-flex">
              <Select
                className="basic-single w-120 mr-3"
                classNamePrefix="select"
                placeholder="Month"
                styles={selectHideArrowStyle}
                value={month}
                isClearable
                onChange={(selectedOption) => {
                  setMonth(selectedOption);
                  selectedOption ? sessionStorage.setItem("bdo-selectedMonth", selectedOption?.value) : sessionStorage.removeItem("dbo-selectedMonth");
                }}
                options={months.map((month, index) => ({ value: index + 1, label: month }))}
              />
              <Select
                className="basic-single w-120"
                classNamePrefix="select"
                placeholder="Year"
                styles={selectHideArrowStyle}
                value={year}
                onChange={(selectedOption) => {
                  setYear(selectedOption);
                  selectedOption ? sessionStorage.setItem("bdo-selectedYear", selectedOption.value) : sessionStorage.removeItem("dbo-selectedYear");
                }}
                options={years.map((year) => ({ value: year, label: year }))}
              />
            </div>
          </div>
        </Header>

        <div className="w-100">
          <Row>
            <Col sm="4">
              <SourceProgess totalSources={dashboardData?.total_sources || 0} activeSources={dashboardData?.total_active_sources || 0} />
            </Col>
            <Col sm="8">
              <DailyStatistics
                totalHires={totalHires}
                totalPI={totalPI}
                totalRepair={totalRepair}
                totalCases={totalCases}
              />
            </Col>
            <Col sm="12">
              <MonthlyStatistics
                pi={dashboardData?.monthly_stats?.total_monthly_pi || 0}
                hire={dashboardData?.monthly_stats?.total_monthly_hire || 0}
                repair={dashboardData?.monthly_stats?.total_monthly_repair || 0}
                topTen={dashboardData?.top_ten_lead_sources}
                month={month?.label}
                year={year.label}
              />
            </Col>
            <Col sm="6">
              <Cases />
            </Col>
            <Col sm="6">
              <PersonalNotes />
            </Col>
          </Row>
        </div>
      </Container>
      <Reminders/>
    </>
  );
});

export default HireSupplierDashboard;
