import React, { useState, useEffect } from "react";
import { Button, Toast, ToastBody, ToastHeader } from "reactstrap";
import instance from "../../axiosInstance";
import { toastr } from "react-redux-toastr";

const Reminders = () => {
  const [reminders, setReminders] = useState([]);
  const [visibleReminders, setVisibleReminders] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchReminders = async () => {
    try {
      const response = await instance.get(`/user/getReminders`);
      setReminders(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReminders();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < reminders.length) {
        const reminderToAdd = reminders[currentIndex];
        setVisibleReminders((prev) => [...prev, { ...reminderToAdd, fading: false }]);
        setCurrentIndex((prev) => prev + 1);

        setTimeout(() => {
          setVisibleReminders((prev) =>
            prev.map((r) =>
              r.id === reminderToAdd.id ? { ...r, fading: true } : r
            )
          );
          setTimeout(() => {
            setVisibleReminders((prev) =>
              prev.filter((r) => r.id !== reminderToAdd.id)
            );
          }, 1000); 
        }, 10000);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex, reminders]);

  const dismissNotification = async (note_type, id) => {
    const body = { note_type, id };
    const dismissedReminder = reminders.find((r) => r.id === id);

    if (dismissedReminder) {
      setVisibleReminders((prev) =>
        prev.map((r) =>
          r.id === id ? { ...r, fading: true } : r
        )
      );
      setTimeout(() => {
        setVisibleReminders((prev) => prev.filter((r) => r.id !== id));
        setReminders((prev) => prev.filter((r) => r.id !== id));
      }, 1000); 
    }

    try {
      await instance.put(`/user/updateReminder`, body);
      toastr.success("The Reminder is Dismissed!");
    } catch (error) {
      console.log(error);
      toastr.error("Failed to dismiss the reminder. Please try again.");

      if (dismissedReminder) {
        setVisibleReminders((prev) => [...prev, dismissedReminder]);
        setReminders((prev) => [...prev, dismissedReminder]);
      }
    }
  };

  const removeReminder = (reminder) => {
    setVisibleReminders((prev) =>
      prev.map((r) =>
        r.id === reminder.id ? { ...r, fading: true } : r
      )
    );
    setTimeout(() => {
      setVisibleReminders((prev) => prev.filter((r) => r.id !== reminder.id));
    }, 1000); 
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 10,
        right: 10,
        zIndex: 1050,
        color: "#fff",
      }}
      className="cc-reminders"
    >
      {visibleReminders.map((reminder) => (
        <Toast
          key={reminder.id}
          className={reminder.fading ? "fade-out" : "fade-in"}
          style={{
            marginBottom: "10px",
            width: "350px",
            background: "#596C8A",
          }}
        >
          <ToastHeader
            toggle={() => removeReminder(reminder)}
            style={{ background: "transparent", color: "#fff" }}
          >
            {reminder?.note_type === "UN" ? (
              "Alert"
            ) : (
              <a
                className="fw-bold fs-3 text-light"
                href={`/administration/cases/${reminder.case_id}/edit`}
              >
                {`NASL-${reminder.case_id}`}
              </a>
            )}
          </ToastHeader>
          <ToastBody>
            {reminder.notes}
            <br />
            {reminder.reminder_date}
            <br />
            <Button
              type="button"
              className="btn btn-light"
              size="sm"
              style={{
                color: "#596C8A",
                margin: "0 0 0 auto",
                display: "flex",
                fontWeight: "600",
              }}
              onClick={() => dismissNotification(reminder.note_type, reminder.id)}
            >
              Dismiss
            </Button>
          </ToastBody>
        </Toast>
      ))}
    </div>
  );
};

export default Reminders;
