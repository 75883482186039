import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import instance from "../../../axiosInstance";
import { useRoleContext } from "../../../context/security/roles";
import { useUserContext } from "../../../context/security/users";
import useError from "../../../hooks/useError";
import { transformData } from "../../../utils/roleForm";
import RequiredLabel from "../../UI/forms/RequiredLabel";
import RoleCollapsibleSection from "../Roles/RoleCollapsibleSection";

const garageTypes = [
  { value: "2 Wheel", label: "2 Wheel" },
  { value: "4 Wheel", label: "4 Wheel" },
];

const yesOrNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const UserControl = memo(() => {
  const {
    userId,
    userType,
    setUserType,
    roles,
    setRoles,
    machineRestricted,
    setMachineRestricted,
    userGroup,
    setUserGroup,
    forPiPanel,
    setForPiPanel,
    forHireSupplier,
    setForHireSupplier,
    piPrefrence,
    setPiPreference,
    hirePrefrence,
    setHirePrefrence,
    userControlsErrors,
    setUserControlsErrors,
    garageType,
    setGarageType,
    userControlsPermssions,
    setUserControlsPermssions,
  } = useUserContext();
  const { setPermissionsGroup } = useRoleContext();
  const { handleError } = useError();

  const [loadings, setLoadings] = useState({
    getingRoles: false,
  });

  const [userTypes, setUserTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generated_token, set_generated_token] = useState(null);
  const [generating, set_generating] = useState(false);

  const managePermissions = (permissions) => {
    const transformPermissions = transformData(permissions);
    setPermissionsGroup(transformPermissions);

    const formattedPermissions = [];
    for (const key in permissions) {
      formattedPermissions.push({ name: key, value: permissions[key] });
    }

    setUserControlsPermssions(() => formattedPermissions);
  };

  const getUserTypes = async () => {
    try {
      setLoading(true);
      const { data } = await instance.get("/lookups/userTypes");
      setUserTypes(data.data.map((type) => ({ value: type, label: type })));
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const generateToken = async () => {
    try {
      set_generating(true);
      const response = await instance.get(`/user/generateToken/${userId}`);
      set_generated_token(response.data?.data?.api_token)
    } catch (error) {
      console.log(error)
      handleError(error);
    } finally {
      set_generating(false);
    }
  };

  useEffect(() => {
    getUserTypes();
  }, []);

  const getRole = async (id) => {
    try {
      setLoadings((pre) => ({ ...pre, getingRoles: true }));
      const res = await instance.get(`/roles/view/${id}`);
      const permissions = res.data.data.permissions;
      managePermissions(permissions);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadings((pre) => ({ ...pre, getingRoles: false }));
    }
  };

  const loadRolesOptions = async (search, loadedOptions, { page }) => {
    try {
      const res = await instance.get("/roles/lists", {
        params: { search, page, limit: 10, status: 1 },
      });

      const { data } = res.data;
      const options = data.data.map((role) => ({
        value: role.role_id,
        label: role.name,
      }));

      return {
        options,
        page: page + 1,
        hasMore: page < data.total_pages,
      };
    } catch (error) {
      handleError(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const loadUserGroupOptions = async (search, loadedOptions, { page }) => {
    try {
      const res = await instance.get("/IPGroups/lists", {
        params: { search, page, limit: 10 },
      });

      const { data } = res.data;
      const options = data.data.map((ipGroup) => ({
        value: ipGroup.ip_group_id,
        label: ipGroup.group_name,
      }));

      return {
        options,
        page: page + 1,
        hasMore: page < data.total_pages,
      };
    } catch (error) {
      handleError(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const PIPanelOptions = async (search, loadedOptions, { page }) => {
    try {
      const res = await instance.get("/lookups/solicitors", {
        params: { search, page, limit: 10 },
      });

      const { data } = res.data;
      const options = data.data.map((item) => ({
        value: item.user_id,
        label: item.full_name,
      }));

      return {
        options,
        page: page + 1,
        hasMore: page < data.total_pages,
      };
    } catch (error) {
      handleError(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const hireSupplierOptions = async (search, loadedOptions, { page }) => {
    try {
      const res = await instance.get("/lookups/hireSuppliers", {
        params: { search, page, limit: 10 },
      });

      const { data } = res.data;
      const options = data.data.map((item) => ({
        value: item.user_id,
        label: item.full_name,
      }));

      return {
        options,
        page: page + 1,
        hasMore: page < data.total_pages,
      };
    } catch (error) {
      handleError(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  

  return (
    <Form>
      <Row>
        <Col md={6} sm={6}>
          <FormGroup>
            <Label for="machineRestricted">Machine Restricted</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="machineRestricted"
              value={machineRestricted ? yesOrNoOptions[0] : yesOrNoOptions[1]}
              options={yesOrNoOptions}
              onChange={(selectedOption) => setMachineRestricted(selectedOption.value)}
            />
          </FormGroup>
        </Col>

        {machineRestricted && (
          <Col md={6} sm={6}>
            <FormGroup>
              <Label for="ipGroup">IP Group</Label>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="ipGroup"
                value={userGroup}
                loadOptions={loadUserGroupOptions}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
                onChange={(selectedOption) => setUserGroup(selectedOption)}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md={6} sm={6}>
          <FormGroup>
            <Label for="setPiPrefrece">Set PI Preference</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="setPiPrefrece"
              value={piPrefrence ? yesOrNoOptions[0] : yesOrNoOptions[1]}
              options={yesOrNoOptions}
              onChange={(selectedOption) => setPiPreference(selectedOption.value)}
            />
          </FormGroup>
        </Col>
        {piPrefrence && (
          <Col md={6} sm={6}>
            <FormGroup>
              <Label for="piPanel">For PI [Panel]</Label>
              <AsyncPaginate
                isMulti
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="piPanel"
                loadOptions={PIPanelOptions}
                additional={{
                  page: 1,
                }}
                value={forPiPanel}
                debounceTimeout={300}
                onChange={(selected) => setForPiPanel(selected)}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md={6} sm={6}>
          <FormGroup>
            <Label for="setHirePrefrence">Set Hire Preference</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="setHirePrefrence"
              value={hirePrefrence ? yesOrNoOptions[0] : yesOrNoOptions[1]}
              options={yesOrNoOptions}
              onChange={(selectedOption) => setHirePrefrence(selectedOption.value)}
            />
          </FormGroup>
        </Col>
        {hirePrefrence && (
          <Col md={6} sm={6}>
            <FormGroup>
              <Label for="hireSupplier">For Hire [Supplier]</Label>
              <AsyncPaginate
                isMulti
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                name="hireSupplier"
                loadOptions={hireSupplierOptions}
                additional={{
                  page: 1,
                }}
                value={forHireSupplier}
                debounceTimeout={300}
                onChange={(selected) => setForHireSupplier(selected)}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <RequiredLabel htmlFor="userType">User Type</RequiredLabel>
            <Select
              className={`basic-single ${userControlsErrors.userType ? "invalid-select" : ""}`}
              classNamePrefix="select"
              isSearchable
              name="userType"
              value={userType}
              isLoading={loading}
              options={userTypes}
              isMulti
              onChange={(selectedOption) => {
                console.log(selectedOption);
                setUserType(selectedOption);
                setUserControlsErrors((pre) => ({
                  ...pre,
                  userType: !selectedOption || !selectedOption?.length ? "User type is required!" : "",
                }));
              }}
            />

            <small className="text-danger">{userControlsErrors.userType}</small>
          </FormGroup>
        </Col>
        {userType?.some((userType) => userType.value === "Garage") && (
          <Col md={6}>
            <FormGroup>
              <RequiredLabel htmlFor="garageType">Garage Type</RequiredLabel>
              <Select
                className={`basic-single ${userControlsErrors.garageType ? "invalid-select" : ""}`}
                classNamePrefix="select"
                isSearchable
                name="garageType"
                value={garageType}
                options={garageTypes}
                onChange={(selectedOption) => {
                  setGarageType(selectedOption);
                  setUserControlsErrors((pre) => ({
                    ...pre,
                    garageType: !selectedOption ? "Garage type is required!" : "",
                  }));
                }}
              />

              <small className="text-danger">{userControlsErrors.garageType}</small>
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md={6} sm={6}>
          <FormGroup>
            <RequiredLabel htmlFor="roles">Roles</RequiredLabel>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable
              name="roles"
              loadOptions={loadRolesOptions}
              additional={{
                page: 1,
              }}
              value={roles}
              debounceTimeout={300}
              onChange={(selectedOption) => {
                setRoles(selectedOption);
                getRole(selectedOption.value);
                setUserControlsErrors((pre) => ({
                  ...pre,
                  userRole: !selectedOption ? "User role is required!" : "",
                }));
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: userControlsErrors.userRole ? "#DC3545" : baseStyles.borderColor,
                }),
              }}
            />
            <small className="text-danger">{userControlsErrors.userRole}</small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={9}>
          <Input type="textarea" autoComplete="off" id="generated_token" name="generated_token" rows="1" value={generated_token}/>
        </Col>
        <Col md={3}>
          <Button color="primary" outline type="button" className="" onClick={generateToken} >
            {generating && <Spinner size="sm" color="#fff" className="mx-1" />}
            Generate Token
          </Button>

        </Col>
      </Row>

      {loadings.getingRoles && (
        <div className="text-center .min-vh-25 mt-4">
          <Spinner color="primary" style={{ width: "2rem", height: "2rem" }} />
        </div>
      )}

      {!loadings.getingRoles && (
        <div className="accordion">
          {userControlsPermssions.map((permission, index) => (
            <RoleCollapsibleSection key={permission.name} id={permission.name} name={permission.name} isDefaultOpen={index === 0} subSections={permission.value} />
          ))}
        </div>
      )}
    </Form>
  );
});

export default UserControl;
