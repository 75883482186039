import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row, Spinner, Table } from "reactstrap";
import instance from "../../axiosInstance";
import useError from "../../hooks/useError";
import { toYYYYMMDD } from "../../utils/forms";
import { Link } from "react-router-dom";
import ParamsLink from "../UI/links/ParamsLink";

const Invoices = memo(() => {
  const { handleError } = useError();

  const [invoices, setInvoices] = useState([]);
  const [isInvoicesGetting, setIsInvoicesGetting] = useState(false);

  const getInvoices = async () => {
    try {
      setIsInvoicesGetting(true);
      const { data } = await instance.get("/SelfInvoice/lists?limit=10");

      console.log(data.data.data);
      setInvoices(data.data.data);
    } catch (err) {
      handleError(err);
    } finally {
      setIsInvoicesGetting(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5">Invoices</CardTitle>
          </Col>

          <Col className="col-auto">
            <div className="avatar">
              <div className="avatar-title rounded-circle bg-primary-dark">
                <FontAwesomeIcon icon={faFileInvoice} size="lg" />
              </div>
            </div>
          </Col>
        </Row>
        <div className="h-306 overflow-auto mt-2">
          {!isInvoicesGetting && invoices.length === 0 && (
            <div className="p-4 d-flex justify-content-center align-items-center h-full">
              <h4>
                <FontAwesomeIcon icon={faFileInvoice} size="lg" /> No Invoices!
              </h4>
            </div>
          )}

          {isInvoicesGetting && (
            <div className="p-4 d-flex justify-content-center align-items-center h-full">
              <Spinner color="primary" />
            </div>
          )}

          {invoices?.length !== 0 && (
            <>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th scope="col">Sr.#</th>
                    <th scope="col">Invoice No.</th>
                    <th scope="col">Invoice Date</th>
                  </tr>
                </thead>

                <tbody>
                  {invoices?.map((invoice) => (
                    <tr key={invoice.self_invoice_id}>
                      <td>{invoice.sr}</td>
                      <td>
                        <ParamsLink to={`/administration/selfBilling/${invoice.self_invoice_id}`} className="custom-link-underline">
                          {invoice.self_invoice_id}
                        </ParamsLink>
                      </td>
                      <td>{toYYYYMMDD(invoice.invoice_date, true)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Link to="/administration/selfBilling">
                <Button color="primary" className="w-100 my-2">
                  View All
                </Button>
              </Link>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
});

export default Invoices;
