import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import instance from "../../axiosInstance";
import { useDeoContext } from "../../context/dashboard/DeoDashboardContext";
import { useProfileContext } from "../../context/profile";
import useError from "../../hooks/useError";
import Header from "../UI/Header";
import HeaderSubtitle from "../UI/HeaderSubtitle";
import HeaderTitle from "../UI/HeaderTitle";
import Loader from "../UI/Loader";
import DashboardCard from "./DashboardCard";
import Invoices from "./Invoices";
import MonthsCart from "./MonthsCart";
import Notifications from "./Notifications";

import { Link } from "react-router-dom";
import MonthlyServiceChart from "./MontlyServiceChart";
import Reminders from "./Reminders";

const DeoDashboard = memo(() => {
  const { profile } = useProfileContext();
  const { handleError } = useError();

  const { deo, setDeo, isLoading, setIsLoading } = useDeoContext();

  const hasCaseAddPermission = profile?.permission?.case?.add;

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await instance.get("/dashboard/deo");
      setDeo(data.data);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>{profile?.full_name}</HeaderTitle>
          <HeaderSubtitle>
            <span className="fs-18">This Month Earnings:</span> <span className="fs-22">£ {deo?.monthly_earning || 0}</span>
          </HeaderSubtitle>
        </Header>

        <div className="w-100">
          <Row>
            <Col sm={hasCaseAddPermission ? "4" : "6"}>
              <Link to="/administration/cases" className="custom-link">
                <DashboardCard title="Total Cases" value={deo?.total_cases || 0} icon={<FontAwesomeIcon icon={faBriefcase} size="lg" />} />
              </Link>
            </Col>
            <Col sm={hasCaseAddPermission ? "4" : "6"}>
              <DashboardCard title="This Month Cases" value={deo?.current_cases || 0} icon={<FontAwesomeIcon icon={faBriefcase} size="lg" />} />
            </Col>
            {hasCaseAddPermission ? (
              <Col sm="4">
                <Link to="/administration/addNewCase" className="custom-link">
                  <DashboardCard title="Add New Claim" value="Add New" icon={<FontAwesomeIcon icon={faBriefcase} size="lg" />} className="cursor-pointer" />
                </Link>
              </Col>
            ) : null}
          </Row>
        </div>


        <div className="w-100">
          <Row>
            <Col sm="6">
              <MonthsCart />
            </Col>
            <Col sm="6">
              <MonthlyServiceChart />
            </Col>
            <Col sm="6">
              <Notifications notifications={deo?.notes || []} />
            </Col>
            <Col sm="6">
              <Invoices />
            </Col>
          </Row>
        </div>
      </Container>
      <Reminders/>
    </>
  );
});

export default DeoDashboard;
