import axios from "axios";
import moment from "moment";

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result.split(",")[1]); // Extracting the base64 data (excluding the data URI prefix)
    };

    reader.onerror = (error) => {
      reject(error);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file provided."));
    }
  });
};

export function isBase64Image(str) {
  return /^data:image\/(png|jpg|jpeg|gif);base64,/.test(str);
}

export const fetchAddressByPostcode = async (postCode) => {
  try {
    const response = await axios.get(`https://api.postcodes.io/postcodes/${postCode}`);
    if (response.data.status === 200) {
      const newAddress = `${response.data.result.admin_ward}, ${response.data.result.admin_district},  ${response.data.result.nhs_ha}, ${response.data.result.country}, ${response.data.result.postcode}`;
      return { address: newAddress, latitude: response.data.result.latitude, longitude: response.data.result.longitude };
    }
  } catch (error) {
    return error.response.data.status;
  }
};

export function toYYYYMMDD(dateString, reverse = false) {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date)) return "";

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
  const year = date.getFullYear();

  // Padding single-digit day and month with leading zeros
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;

  // if reverse is false return yyyy-mm-dd else dd-mm-yyyy
  return reverse ? `${formattedDay}-${formattedMonth}-${year}` : `${year}-${formattedMonth}-${formattedDay}`;
}

export const toDateObj = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return isNaN(date) ? "" : date;
};

export function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth returns zero-based index
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
}

export const toTimeObj = (timeString) => {
  // Try parsing with seconds, then without if it fails
  let time = moment(timeString, ["HH:mm:ss", "HH:mm"], true);
  return time.isValid() ? time.toDate() : null;
};

export const toTimeFormat = (timeString) => {
  const time = moment(timeString, "HH:mm", true);
  return time.isValid() ? time.format("HH:mm") : "";
};

export const toDateAndTimeObj = (dateTimeString) => {
  if (!dateTimeString) return ""; // Check for null, undefined, or empty string
  
  // Convert to ISO format if necessary
  const isoString = dateTimeString.replace(" ", "T");
  const dateObj = new Date(isoString);
  if (isNaN(dateObj)) return ""; // Check for invalid date string
  
  const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
  const time = dateTimeString.split(" ")[1] || ""; // Extract time part if available

  const formattedDate = dateObj.toLocaleDateString("en-GB", dateOptions).replace(/\//g, "-");
  return `${formattedDate} ${time}`;
};


export function getFirstAndLastDateOfMonth(year, month) {
  // Create a Moment.js object for the first day of the specified month
  var firstDayOfMonth = moment({ year: year, month: month - 1 }).startOf("month");

  // Create a Moment.js object for the last day of the specified month
  var lastDayOfMonth = moment({ year: year, month: month - 1 }).endOf("month");

  // Format the first and last dates as "yyyy-mm-dd"
  var firstDateFormatted = firstDayOfMonth.format("YYYY-MM-DD");
  var lastDateFormatted = lastDayOfMonth.format("YYYY-MM-DD");

  // Return an object containing the first and last dates in "yyyy-mm-dd" format
  return {
    firstDate: firstDateFormatted,
    lastDate: lastDateFormatted,
  };
}

export function formatDateTime(dateString, format = "YYYY-MM-DD HH:mm:ss") {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date)) return "";

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Padding single-digit day, month, hours, minutes, and seconds with leading zeros
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  // Replace format tokens with actual values
  let formattedDate = format
    .replace("YYYY", year)
    .replace("MM", formattedMonth)
    .replace("DD", formattedDay)
    .replace("HH", formattedHours)
    .replace("mm", formattedMinutes)
    .replace("ss", formattedSeconds);

  return formattedDate;
}